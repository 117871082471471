import axios from 'axios'
import qs from 'qs';
import { showToast,showFailToast,showLoadingToast,closeToast } from 'vant';
import router from '@/router';
import store from '@/store';
import {getNewToken} from '@/utils/home';
import { getUserInfo } from '@/utils/my';
let pro = process.env;
let base=pro.NODE_ENV == "development" ?  '/api' : '/api-evimed'

export {
  base
};
let timer=null;
const callAppExit = cnative.callFactory('applyAuthorize','_getopenid','Yxcz')
const instance = axios.create({
  baseURL: base,
  timeout: 15*60*1000,
  headers: {
    post: {
      // 'Content-Type': 'application/x-www-form-urlencoded'
      'Content-Type': 'application/json',
    },
    client: 'app',
    versionCode: 3,
    from: ''
  },
  paramsSerializer: { 
    serialize:function(params) {
     return qs.stringify(params, { arrayFormat: 'repeat' })
   }
  },
  withcredentials: true
});
// 进度条页面
let progressPage=[
  '/testDetail',
  '/decision-report-surper',
  '/decision-report-xz',
  '/safedetail',
  '/toadd',
  '/aianalysisdetails',
  '/safetransfer',
  '/safetransfer/safedetail',
  '/safetransfer/toadd',
  '/reportTransfer',
  '/reportTransfer/decision-report-surper',
  '/documents'
]
let isnotLoading=[
  // '/FineScreenController/search',
  '/FineScreenController/appGeneralSearchHistory',
  '/FineScreenController/deleteHistoryByScreenId',
  '/FineScreenController/emptyAppGeneralSearchHistory',
  '/archive-api/guide/filter/get',
  '/system/task/check/get',
  // '/drug-api/countrys/get',
  // 'subscribe-api/getbyclass',
  '/system/user/myself',
  '/analyze-api/topic/history',
  '/FineScreenController/TopicAnalysisController/history',
  '/system/popup/get',
  '/system/balance/account/my',
  '/system/wechat/yydy',
  '/yiling/operation',
  '/system/wechat/operation',
  '/yiling/task/details',
  '/yiling/search/history',
  '/evaluation-api/url-to-base64',
  // 临床综合评价打字机效果
  '/evaluation-api/lxgpt/process',
  '/evaluation-api/guide-on-analysis-app',
  '/evaluation-api/su-on-analysis-app',

  '/alert/getAssociationalWord',
  '/evaluation-api/get-associational-word',
  '/FineScreenController/TopicAnalysisController/history/get',
  '/FineScreenController/TopicAnalysisController/history/delete',

  '/medicine-api/questions/sensitive',
  '/FineScreenController/TopicAnalysisController/keyword',
  '/FineScreenController/collectionLiterature',
  '/evaluation-api/history',
  '/archive-api/guideStatus/put',
  '/archive-api/instructionStatus/put',
  '/alert/showHistory',
  '/archive-api/guide/history/get',
  '/archive-api/guide/latest/',
  '/FineScreenController/paper/recomend/get',
  '/drug-api/drug/history/get',
  '/drug-api/class/get',
  '/archive-api/guide/department/',
  '/FineScreenController/TopicAnalysisController/hot/get',
  'system/verifycode/send',
  '/system/user/updateneedcode',
  '/system/user/svip/check',
  '/yiling/answer/getQuestionAndAnswerInfoByUser',
  '/yiling/answer/getQuestionAndAnswerInfo',
  '/yiling/question/getSysQuestionAnswer',
  '/FineScreenController/TopicAnalysisController/reporting/notice',
  
  '/evidence-api-based/retrieval-api/type-list',
  '/evidence-api/retrieval-api/synonym',
  '/evidence-api/retrieval-api/save-condition',
  '/medicine-api/wechat/create-secret-key',
  '/evidence-api-based/report-api/create',
  '/evidence-api-based/retrieval-api/type-list',
  '/evidence-api-based/retrieval-api/synonym',
  '/evidence-api-based/retrieval-api/save-condition',
  '/yiling/topic/getTopicAndQuestionList',
  '/medicine-api/ai-overview-api/outline',
  '/medicine-api/judging',
  '/medicine-api/translate',
  '/evidence-api/super-manual-api/createPc',
  // 购买相关
  '/system/balance/purchase/situation',
  '/system/balance/lingdou/price',
  '/FineScreenController/getPaperInterpret',
  '/yiling/topic/topicsAndQuestionList',
  '/yiling/sys/getSysMessages',
  '/medicine-api/ai-overview-api/paper-info',

  ]
instance.interceptors.request.use((config)=>{

  if(!isnotLoading.includes(config.url)){
    store.state.loadingNum++
    if(!store.state.noLoading || !progressPage.includes(window.location.pathname)){
      startLoading()
    }
  }
  // 渠道码
  if(window.location.href.includes('ChannelCode')){
    config.headers["from"] = window.location.href;
  }

  if (config.url != 'auth/login' || config.url != '/auth/loginfree') {
    let token = window.localStorage.getItem("token")
    if (token) { //如果有token继续操作 如果没有token  跳转登录页
      config.headers["token"] = token;
    } else {
      config.headers["token"] = '';
    }
    return config;
  }
},(err)=>{
  console.log(err);

})
instance.interceptors.response.use((res)=>{
  // console.log(res.config)
  // removePending(res.config)

  if(!isnotLoading.includes(res.config.url)){
    store.state.loadingNum--;
    if(store.state.loadingNum==0){
      if(timer!=null){
        clearTimeout(timer);
      }
      timer=setTimeout(()=>{
        if(store.state.loadingNum==0){
          if(res.config.url='/FineScreenController/multi/academic_tracking/get'){
            setTimeout(()=>{closeToast()},500)
            // closeToast()
          }else{
            closeToast()
          }
        }
      },200)
    }
  }
  // console.log(res)
  if(res.data.code){
    switch(res.data.code){
      case(200):return Promise.resolve(res.data);break;
      default:
      showToast({
        message:res.data.msg,
        position: 'bottom',
      });
      return Promise.resolve(res.data);
    }
  } 
  console.log(store.state.loadingNum);
},(err)=>{  
  console.log(err)

  if(!isnotLoading.includes(err.config&&err.config.url)){
    store.state.loadingNum--
    if(store.state.loadingNum==0){
      closeToast()
    }
  }
  store.state.noLoading=false;
  if (err&&err.response) {
    const status = err.response.status;
    switch(status){
      case(401):
      callAppExit({clientId:"5f3df22ff0bd3614f27a115d7c849951"},(openid)=>{
        if(openid){
          ysfwToken(openid);
        }else{
          store.state.lastVisitedPath=router.currentRoute.value.fullPath;
          router.replace('/vertifycode');
        }
      })
      break;
    
        // router.push('/vertifycode');
      default:
        store.state.reloadFlag=true;
        showFailToast('服务繁忙');
    }
    
  }
  else{
    if(err.config && err.config.url=='/forward/evidence-api-based/report-api/create'){
      console.log('报告生成中断');
    }else{
      store.state.reloadFlag=true;
      showFailToast('服务繁忙');
      console.log(store.state.reloadFlag);
    }
  }
  console.log(err);
  return Promise.reject('')
})
const request=(option)=>{
  // console.log({...option})
  return new Promise((resolve,reject)=>{
    instance.request({
     ...option,
    }).then((res)=>{
      resolve(res)
    })
    .catch((res)=>{
      reject(res)
    })
  })
}
const startLoading=()=>{
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    // backgroundColor: 'black',
    icon: require('@/assets/aiselect/isloading.gif'),
    duration:0,
    // className:"isallloading",
    // overlay:true
  });
}
// 医师服务生成token
const ysfwToken=(openid)=>{
  const clientId = "5f3df22ff0bd3614f27a115d7c849951";
  const returnUrl = encodeURIComponent("https://wap.evimed.com/home");
  // const returnUrl = encodeURIComponent("https://70816s008v.goho.co/home");
  // const returnUrl = encodeURIComponent("http://20.20.20.11:8080/home");
  // const returnUrl = encodeURIComponent("https://m.evimed.com/home");
  const loginUrl = "https://login.yishifuwu.cn/app/index.html?clientId=";
  // alert(`${loginUrl}${clientId}&returnUrl=${returnUrl}&type=base`)
  if(router.currentRoute.value.query.oauthCode&&router.currentRoute.value.query.oauthCode!=0){
    let codes=router.currentRoute.value.query.oauthCode
    localStorage.setItem('oauthCode',codes)
    getNewToken({authCode:codes}).then((res)=>{
      if(res.code==200){
        localStorage.setItem('token',res.token)
        localStorage.setItem('openid',openid)
        getUserInfo().then(re=>{
          store.commit("updateUserInfo", re.data);
          location.reload();
        })
      }
    })
    .catch((e)=>{
    })
  }
  else if(router.currentRoute.value.query.oauthCode==0){
    window.location.replace(`${loginUrl}${clientId}&returnUrl=${returnUrl}&type=base`);
  }
  else{
    window.location.replace(`${loginUrl}${clientId}&returnUrl=${returnUrl}&type=base`);
  }
}
export default request
